@import '../../styles/shared.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  font-size: 3.24em;
  margin-top: .75em;
  margin-bottom: .75em;
  text-shadow: 0px 0px 3px chartreuse;
}

.input {
  width: 25%;
  background: none;
  border: none;
  outline: none;
  border-bottom: 2px solid chartreuse;
  font-family: 'Fira Code';
  font-size: 2.5em;
  text-align: center;
  color: rgb(77, 210, 255);
  margin-bottom: 1em;
}

.button {
  @include btn-style;
}
