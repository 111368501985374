@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

@mixin btn-style {
  background: none;
  outline: none;
  color: chartreuse;
  font-family: 'Fira Code';
  font-size: 2.4em;
  padding: .24em;
  margin-bottom: 1em;
  transition: all .24s ease-in-out;
  border-radius: 4px;

  &:hover {
    border: 2px solid chartreuse;
    border-radius: 10px;
    box-shadow: inset 0px 0px 8px chartreuse, 0px 0px 8px chartreuse;
    text-shadow: 0px 0px 5px chartreuse;
    cursor: pointer;
  }
}
