@import '../../styles/shared.scss';

.TodoCard {
  border: 2px solid chartreuse;
  border-radius: 10px;
  box-shadow: inset 0px 0px 8px chartreuse, 0px 0px 8px chartreuse;
  text-shadow: 0px 0px 2px chartreuse;
  margin: 1em;
  padding: 0 1.3em;
  width: fit-content;
  
  h2 {
    font-size: 2em;
    letter-spacing: 2px;
  }
}

.timeStamp {
  font-size: 1.5em;
}

.button {
  @include btn-style;
  font-size: 1.8em;
}
